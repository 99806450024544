<template>
  <div class="storeBox excelBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          外部报表
        </div>
        <div class="card-hd-right">
        </div>
      </div>
    </div>
    <div class="handleLineBox">
      <div class="baseSelect">
        <!-- 下拉选择 -->
        <SelectDown
          :label="'后勤模板'"
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <Refresh class="refresh-between" @refreshCall="refreshHandle('template')" title=""></Refresh>
        <el-button type="primary" icon="CirclePlus" @click="pushRouter('addTemplate')">新增后勤模板</el-button>
        <!-- 弹窗 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="SimpleDialogClose"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  新增报表分析
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsHd.currentIndex"
                    @tab-click="tabsListHandle($event, tabsHd, baseData, goodsFormScoll)">
                    <el-tab-pane
                      v-for="item in tabsHd.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll($event, tabsHd, baseData)"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormRef"
                        :model="ruleForm"
                        status-icon
                        :rules="rules"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="报表分析名称" prop="templateName">
                                <el-input v-model="ruleForm.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="请填写报表分析名称"
                                  show-word-limit
                                  clearable
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              报表分析详情
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div v-if="sortOutData.deconstructionDataList?.length > 0"
                            class="height-line-table"
                          >
                            <el-table
                              :data="sortOutData.deconstructionDataList"
                              stripe
                              style="width: calc(100vw - 24px);">
                              <el-table-column
                                v-for="(item, index) in sortOutData.deconstructionHead"
                                :key="index"
                                :prop="item.prop"
                                :label="item.label + (item.unit ? '(' + item.unit + ')' : '')"
                                :fixed="item?.fixed"
                                :min-width="item?.width"
                                align="center"
                              >
                                <template #default="scope">
                                  <template v-if="item.prop == 'orderNumDetail'">
                                    <el-table-column
                                      v-for="(itemChild, indexChild) in baseData.desconstructionHeadLevel2"
                                      :key="indexChild"
                                      :prop="itemChild.prop"
                                      :label="itemChild.label"
                                      :min-width="itemChild?.width"
                                      align="center"
                                    >
                                      <template #default="scopeChild">
                                        <div class="status-table-clumn">
                                          <span>{{ scopeChild.row[itemChild.prop].orderNum }}单</span>
                                          <span>{{ scopeChild.row[itemChild.prop].moneySum }}元</span>
                                        </div>
                                      </template>
                                    </el-table-column>
                                  </template>
                                  <span v-else-if="item.prop == 'date'">
                                    {{ scope.row[item.prop] }}
                                  </span>
                                  <span v-else>
                                    {{ scope.row[item.prop] }}
                                  </span>
                                </template>
                              </el-table-column>
                            </el-table>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <SelectDown
          :label="'SKU成本'"
          :selectVal="baseData.selectValSkuCost"
          :selectData="baseData.selectDataSkuCost"
          @selectDownCall="selectDownSkuCostHandle"
        ></SelectDown>
        <Refresh class="refresh-between" @refreshCall="refreshHandle('skuCost')" title=""></Refresh>
        <el-button type="primary" icon="CirclePlus" @click="pushRouter('addSkucost')">新增SKU成本表</el-button>
        <SelectDown
          :label="'运营费用'"
          :selectVal="baseData.selectValOperateFee"
          :selectData="baseData.selectDataOperateFee"
          @selectDownCall="selectOperateFeeHandle"
        ></SelectDown>
        <Refresh class="refresh-between" @refreshCall="refreshHandle('operateFee')" title=""></Refresh>
        <el-button type="primary" icon="CirclePlus" @click="pushRouter('addOperateFee')">新增运营费用表</el-button>
      </div>
    </div>
    <div class="handleLineBox">
      <div class="baseSelect ordersFilterSelect">
        <p class="selectLabel">订单过滤</p>
        <el-select
          class="orders-filter-select"
          v-model="baseData.ordersFilterPs"
          multiple
          filterable
          allow-create
          default-first-option
          :reserve-keyword="false"
          placeholder="请输入或选择订单备注过滤对应关键字，支持回车确认"
          no-data-text="请输入或选择订单备注过滤对应关键字，支持回车确认"
        >
          <el-option
            v-for="item in baseData.ordersFilterPsArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button class="upload-btn" type="primary" icon="Upload" @click="comHandleHandleUpload()" v-if="exlcelData?.header.length !== 0">重新上传外部报表</el-button>
      </div>
    </div>

    <UploadExcel
      :onSuccess="excelCall"
      ref="UploadExcelCom"
      v-show="exlcelData?.header.length === 0"
    ></UploadExcel>

    <div class="echart-demo">
      <div id="myChart3" class="echart-box" v-if="false"></div>
      <div id="myChartLast" class="echart-box"></div>
      <div class="file-save-entry" v-show="exlcelData?.header.length !== 0">
        <p>{{ sortOutData.fileName }}</p>
        <el-button type="primary" @click="saveReportShowHandle">保存本次报表分析</el-button>
      </div>
    </div>

  </div>
</template>

<script setup>
// eslint-disable-next-line
import UploadExcel from '@/components/UploadExcel/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { onMounted, reactive, ref, watch } from 'vue'
import { colorArr, createTimeBarTickEchart, createBarTickEchart } from '@/utils/echart/echartSet.js'
import { ordernumToTime } from '@/utils/unitTransform.js'
import SelectDown from '@/components/SelectDown/index.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { logisticsTemplateList, skuCostList, operateFeeList, logisticsTemplateDetail, skuCostDetail, operateFeeDetail, reportAnalysisAdd } from '@/api/report.js'
import { tabsListHandle, doScroll, getCardItemSite } from '@/utils/general.js'
import { isOrderStatus } from '@/utils/const.js'
import { initSummaryItem, summarySortOut } from '@/utils/Export2Excel.js'
import { useRouter } from 'vue-router'
import md5 from 'md5'

const router = useRouter()
const UploadExcelCom = ref(null)
const exlcelData = reactive({
  header: [],
  results: []
})
const sortOutData = reactive({
  templateDefind: {}, // 已选模板对应的模板字段详情
  baseArr: [],
  idArr: [],
  skuArr: [],
  skuIdArr: [],
  orderArr: [],
  orderTypeArr: [],
  takeOverDetailArr: [],
  takeOverTimeArr: [],
  templateDefindSkuCost: {}, // 已选SKU成本表模板对应的模板字段详情
  templateDefindOperateFee: {}, // 已选运营费用表模板对应的模板字段详情
  deconstructionDataList: [], // 最后展示整合后的平铺数据
  deconstructionHead: [], // 最后展示整合后的平铺数据的表格头部
  fileName: '', // 上传的文件名
  deconstructionSummary: {} // 最后展示整合后的平铺数据的总和
})
const baseData = reactive({
  selectVal: '',
  selectData: [],
  SimpleDialogShow: false,
  selectValSkuCost: '',
  selectDataSkuCost: [],
  selectValOperateFee: '',
  selectDataOperateFee: [],
  SkuDialogShow: false,
  desconstructionHeadLevel2: [
    { label: '待付款', prop: '待付款', width: '100' },
    { label: '已取消', prop: '已取消', width: '100' },
    { label: '待发货', prop: '待发货', width: '100' },
    { label: '未发货，退款成功', prop: '未发货，退款成功', width: '150' },
    { label: '已发货，待收货', prop: '已发货，待收货', width: '150' },
    { label: '已发货，退款成功', prop: '已发货，退款成功', width: '150' },
    { label: '已收货', prop: '已收货', width: '100' },
    { label: '已收货，退款成功', prop: '已收货，退款成功', width: '150' }
  ],
  ordersFilterPs: '', // 订单过滤制定备注
  ordersFilterPsArr: [], // 订单过滤制定备注数组
  ordersFilterPsCount: 0 // 订单过滤个数
})

// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
const tabsHd = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '报表分析详情', name: 1 }
  ]
})

// tabs currentIndex监听
watch(() => tabsHd.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite(tabsHd)
})

// 后勤模板表单 action
// 表单 信息
const ruleForm = reactive({
  templateName: '', // 模板名称
  trueOrderSelectArr: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，退款成功', '已收货，退款成功'], // 有效订单=减去对应类型(减法)
  trueOrderTypeSelect: [
    {
      value: '1',
      label: '待付款'
    },
    {
      value: '2',
      label: '已取消'
    },
    {
      value: '3',
      label: '待发货'
    },
    {
      value: '4',
      label: '未发货，退款成功'
    },
    {
      value: '5',
      label: '已发货，待收货'
    },
    {
      value: '6',
      label: '已发货，退款成功'
    },
    {
      value: '7',
      label: '已收货'
    },
    {
      value: '8',
      label: '已收货，退款成功'
    }
  ],
  globalLaborFee: '', // 全局人工费
  globalExpressdeliveryFee: '' // 全局快递费
})
// 表单 验证相关
const ruleFormRef = ref()
// 表单 模板名校验规则
const checkTemplateName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
// 表单 费用校验规则
const checkFee = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请设置费用'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rules = reactive({
  templateName: [{ required: true, validator: checkTemplateName, trigger: 'blur' }],
  globalLaborFee: [{ required: true, validator: checkFee, trigger: 'blur' }],
  globalExpressdeliveryFee: [{ required: true, validator: checkFee, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitForm = (formEl) => {
  if (!formEl) return
  console.error(baseData)
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleForm)
      submitReportHandle()
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl) => {
  if (!formEl) return
  ElMessageBox.confirm(
    '重置后所有未保存的内容均会丢失，是否继续？',
    {
      confirmButtonText: '重置',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    formEl.resetFields()
  }).catch(() => {
  })
}
// simpleDialog 关闭事件
const SimpleDialogClose = () => {
  baseData.SimpleDialogShow = false
}
const pushRouter = (type) => {
  console.log('pushRouter', type)
  switch (type) {
    case 'addSkucost':
      router.push({ name: 'reportSkuCost', params: { event: 'addSkucost' } })
      break
    case 'addTemplate':
      router.push({ name: 'reportTemplate', params: { event: 'addTemplate' } })
      break
    case 'addOperateFee':
      router.push({ name: 'reportOperateFee', params: { event: 'addOperateFee' } })
      break
  }
}
// 下拉选择相关
const selectDownHandle = async (val) => {
  baseData.selectVal = val
  sortOutData.templateDefind = {}
  const resData = await logisticsTemplateDetail({
    template_id: baseData.selectVal
  })
  // 别名重新定义
  const {
    expected_express_fee: globalExpressdeliveryFee,
    expected_labor_cost: globalLaborFee,
    invalid_order_status: trueOrderSelectArr,
    template_id: templateId,
    id
  } = resData
  // 有效订单 — 物流状态映射中文
  const nameChangetrueOrderSelectArr = []
  for (const i in ruleForm.trueOrderTypeSelect) {
    if (JSON.parse(trueOrderSelectArr).indexOf(ruleForm.trueOrderTypeSelect[i].value) !== -1) {
      nameChangetrueOrderSelectArr.push(ruleForm.trueOrderTypeSelect[i].label)
    }
  }
  const nameChange = {
    globalExpressdeliveryFee,
    globalLaborFee,
    trueOrderSelectArr: nameChangetrueOrderSelectArr,
    templateId,
    id
  }
  sortOutData.templateDefind = nameChange
  console.log('sortOutData.templateDefindsortOutData.templateDefind', sortOutData)
}

// 后勤模板表单 end

// 下拉选择相关
const selectDownSkuCostHandle = async (val) => {
  console.error('下拉选择相关', sortOutData, val)
  baseData.selectValSkuCost = val
  const resData = await skuCostDetail({
    template_id: baseData.selectValSkuCost
  })
  sortOutData.templateDefindSkuCost = resData
}
// Sku成本表表单 end
// 基础组件 事务 end

// 整理事件 - 运营费用表分析
const operateFeeComputerHandle = (baseItem) => {
  const { duizhang, tuiguang } = sortOutData.templateDefindOperateFee
  const duizhangIndexOf = ['jinbao_yongjin', 'other_fuwu', 'jishufuwu_fee', 'koukuan', 'youhuiquan', 'haoping_lijin', 'other_zhichu']
  const tuiguangIndexOf = ['tuiguang_fee']
  function findCommonElements(arr1, arr2) {
    return arr1.filter(item => arr2.includes(item))
  }
  for (const i in duizhang) {
    if (duizhang[i].date === baseItem.date) {
      Object.assign(baseItem.expend.duizhang, duizhang[i])
      const commonElements = findCommonElements(duizhangIndexOf, Object.keys(duizhang[i]))
      for (const j in Object.keys(duizhang[i])) {
        if (commonElements.indexOf(Object.keys(duizhang[i])[j]) !== -1) {
          baseItem.expend.duizhangSum += Number(Object.values(duizhang[i])[j])
        }
      }
      break
    }
  }
  for (const i in tuiguang) {
    if (tuiguang[i].date === baseItem.date) {
      Object.assign(baseItem.expend.tuiguang, tuiguang[i])
      const commonElements = findCommonElements(tuiguangIndexOf, Object.keys(tuiguang[i]))
      for (const j in Object.keys(tuiguang[i])) {
        if (commonElements.indexOf(Object.keys(tuiguang[i])[j]) !== -1) {
          baseItem.expend.tuiguangSum += Number(Object.values(tuiguang[i])[j])
        }
      }
      break
    }
  }
}

// 整理事件 - SKU成本表分析
const skuCostComputerHandle = (dayTrueOrderItem, dayOrderItem) => {
  // 遍历指定日期有效订单列表
  for (const i in dayTrueOrderItem.list) {
    const item = dayTrueOrderItem.list[i]
    let skuName = ''
    // 匹配sku名称，已知表头命名目前有两个—— 商家编码-规格维度、商品规格
    if (item['商家编码-规格维度']) {
      skuName = item['商家编码-规格维度']
    } else if (item['商品规格']) {
      skuName = item['商品规格']
    }
    // 遍历sku名称遍历sku成本表，匹配sku成本表，获取sku成本价格
    for (const j in sortOutData.templateDefindSkuCost) {
      const skuCostItem = sortOutData.templateDefindSkuCost[j]
      if (skuName === skuCostItem.sku_name) {
        dayOrderItem.trueOrders.skuCostSum += Number(skuCostItem.cost_price)
        break
      }
    }
  }
}

// 整理事件 - 订单比率层
const sortOrderRatioHandle = () => {
  for (const i in sortOutData.orderArr) {
    const list = sortOutData.orderArr[i].list
    const type = sortOutData.orderArr[i].type
    // 各个发货状态订单数占总订单比
    sortOutData.orderArr[i].ratio = (sortOutData.orderArr[i].orderNum / exlcelData.results.length).toFixed(4)
    // 各个发货状态sku销量统计
    for (const j in list) {
      const listItem = list[j]
      const skuIdIndex = sortOutData.skuIdArr.indexOf(listItem['样式ID'])
      const goodIdIndex = sortOutData.idArr.indexOf(listItem['商品id'])
      if (skuIdIndex !== -1) {
        // 该sku对应商品所有订单状态总销量统计
        !sortOutData.baseArr[goodIdIndex]?.sum ? (sortOutData.baseArr[goodIdIndex].sum = 1) : (sortOutData.baseArr[goodIdIndex].sum += 1)
        // 该sku对应商品各个订单状态总销量统计
        sortOutData.baseArr[goodIdIndex][`sum${type}`] ? (sortOutData.baseArr[goodIdIndex][`sum${type}`] += 1) : (sortOutData.baseArr[goodIdIndex][`sum${type}`] = 1)
        // 该sku所有订单状态总销量统计
        !sortOutData.skuArr[skuIdIndex]?.sum ? (sortOutData.skuArr[skuIdIndex].sum = 1) : (sortOutData.skuArr[skuIdIndex].sum += 1)
        // 该sku各个订单状态总销量统计
        sortOutData.skuArr[skuIdIndex][`sum${type}`] ? (sortOutData.skuArr[skuIdIndex][`sum${type}`] += 1) : (sortOutData.skuArr[skuIdIndex][`sum${type}`] = 1)
      }
    }
  }
  // console.log('~~', sortOutData)
}

// 整理事件 - 订单状态层
const sortOrderHandle = (item) => {
  const ordeStatusItem = item['订单状态']
  const itemIndexof = sortOutData.orderTypeArr.indexOf(ordeStatusItem)
  if (itemIndexof === -1) {
    sortOutData.orderTypeArr.push(ordeStatusItem)
    sortOutData.orderArr.push({
      type: ordeStatusItem,
      list: [item]
    })
  } else {
    sortOutData.orderArr[itemIndexof].list.push(item)
  }
}

// 整理事件 - SKU层
const sortSkuHandle = (skuid, item) => {
  const skuItem = item['样式ID']
  if (sortOutData.skuIdArr.indexOf(skuItem) === -1) {
    sortOutData.skuArr.push(item)
    sortOutData.skuIdArr.push(skuid)
  }
}

// 整理事件 - 时间层
const sortOrderTimeHandle = async () => {
  for (const i in sortOutData.takeOverDetailArr) {
    const items = sortOutData.takeOverDetailArr[i]
    const itemsStatus = items.status
    const listItem = items.list
    if (listItem.length > 0) {
      for (const j in listItem) {
        const item = listItem[j]
        const timeItemStatus = item['订单状态']
        for (const z in itemsStatus) {
          if (itemsStatus[z].type === timeItemStatus) {
            itemsStatus[z].moneySum += item['商家实收金额(元)']
            itemsStatus[z].orderNum += 1
            itemsStatus[z].list.push(item)
            // 当天总和
            items.moneySum += item['商家实收金额(元)']
          }
        }
      }
    }
  }
}

// 整理数据 - 集合模板对数据进行二次整合
const templateIntegrationHandle = () => {
  const templateDefind = (Object.keys(sortOutData.templateDefind).length !== 0)
  const templateDefindSkuCost = (Object.keys(sortOutData.templateDefindSkuCost).length !== 0)
  const templateDefindOperateFee = (Object.keys(sortOutData.templateDefindOperateFee).length !== 0)
  if (!templateDefind) {
    ElMessage({
      message: '暂未使用后勤模板，无法统计有效订单、每单快递费，每单人工费',
      type: 'error',
      showClose: true,
      duration: 6000
    })
    return
  }
  if (!templateDefindSkuCost) {
    ElMessage({
      message: '暂未使用SKU成本表，无法统计商品成本，销售毛利，毛利率',
      type: 'error',
      showClose: true,
      duration: 6000
    })
  }
  if (!templateDefindOperateFee) {
    ElMessage({
      message: '暂未使用运营费用表，无法统计当天推广支出和其他支出',
      type: 'error',
      showClose: true,
      duration: 6000
    })
  }
  const { globalExpressdeliveryFee, globalLaborFee, trueOrderSelectArr } = sortOutData.templateDefind
  for (const i in sortOutData.takeOverDetailArr) {
    const takeOverItem = sortOutData.takeOverDetailArr[i]
    takeOverItem.trueOrders = {
      moneySum: 0,
      orderNum: 0,
      skuCostSum: 0
    }
    for (const j in takeOverItem.status) {
      const conditionLength = takeOverItem.status[j].orderNum
      const conditioMoneySum = takeOverItem.status[j].moneySum
      if (trueOrderSelectArr.indexOf(takeOverItem.status[j].type) === -1) {
        // 累加快递费
        if (takeOverItem.feeExpressdelivery) {
          takeOverItem.feeExpressdelivery += (globalExpressdeliveryFee * conditionLength)
        } else {
          takeOverItem.feeExpressdelivery = (globalExpressdeliveryFee * conditionLength)
        }
        // 累加人工费
        if (takeOverItem.feeLabor) {
          takeOverItem.feeLabor += (globalLaborFee * conditionLength)
        } else {
          takeOverItem.feeLabor = (globalLaborFee * conditionLength)
        }
        // 计算真实订单
        takeOverItem.trueOrders.moneySum += conditioMoneySum
        takeOverItem.trueOrders.orderNum += conditionLength
        // SKU成本维度
        // 结合SKU成本表，计算商品总成本，销售毛利，毛利率
        templateDefindSkuCost && skuCostComputerHandle(takeOverItem.status[j], takeOverItem)
      }
    }
    // 执行运营费用表分析事件
    templateDefindOperateFee && operateFeeComputerHandle(takeOverItem)
  }
}

// 整理事件 - 顶层
const sortOutHandle = (loadingInstance) => {
  for (const i in exlcelData.results) {
    const item = exlcelData.results[i]
    const idItem = item['商品id']
    const skuIdItem = item['样式ID']
    const takeOverTimeItem = ordernumToTime(item['订单号'])
    // 商品id维度
    if (sortOutData.idArr.indexOf(idItem) === -1) {
      sortOutData.baseArr.push(item)
      sortOutData.idArr.push(idItem)
      sortOutData.skuArr.push(item)
      sortOutData.skuIdArr.push(skuIdItem)
    } else {
      sortSkuHandle(skuIdItem, item)
    }
    sortOrderHandle(item)
    // 订单时间维度 - 1
    const itemIndexof = sortOutData.takeOverTimeArr.indexOf(takeOverTimeItem)
    if (sortOutData.takeOverTimeArr.indexOf(takeOverTimeItem) === -1) {
      console.error('orderNum的问题合理出现在这一块')
      sortOutData.takeOverTimeArr.push(takeOverTimeItem)
      const tempOrderTypeObj = []
      // 初始化订单状态obj start
      const defindOrderTypeArr = isOrderStatus
      for (const i in defindOrderTypeArr) {
        tempOrderTypeObj.push({
          type: defindOrderTypeArr[i],
          list: [],
          moneySum: 0,
          orderNum: 0,
          expend: {
            duizhang: {},
            tuiguang: {},
            duizhangSum: 0,
            tuiguangSum: 0
          }
        })
      }
      // 初始化订单状态obj end
      sortOutData.takeOverDetailArr.push({
        date: takeOverTimeItem,
        list: [item],
        status: tempOrderTypeObj,
        moneySum: 0,
        orderNum: 1,
        expend: {
          duizhang: {},
          tuiguang: {},
          duizhangSum: 0,
          tuiguangSum: 0
        }
      })
    } else {
      sortOutData.takeOverDetailArr[itemIndexof].list.push(item)
      sortOutData.takeOverDetailArr[itemIndexof].orderNum += 1
    }
  }
  sortOrderRatioHandle()
  // 订单时间维度 - 2
  sortOrderTimeHandle().then(() => {
    // 集合模板对数据进行二次整合
    templateIntegrationHandle()
    // 进行最后的数据解构，用于表格展示
    deconstructionDataShow(sortOutData)
    // 初始化柱状商品销量图
    if (templateIntegrationHandle === 10086) {
      createBarTickEchart(
        sortOutData.baseArr,
        sortOutData.orderTypeArr,
        isOrderStatus,
        colorArr,
        document.getElementById('myChart3')
      )
    }
    // 初始化柱状时间销量图
    createTimeBarTickEchart(
      sortOutData.takeOverDetailArr,
      isOrderStatus,
      colorArr,
      document.getElementById('myChartLast')
    )
  }).catch((error) => {
    ElMessage({
      message: '报表解析出错',
      type: 'error',
      showClose: true,
      duration: 6000
    })
    console.log(error)
    clearExcelData()
  })
  console.log('takeOverDetailArr最后数据整合', sortOutData.takeOverDetailArr)
  console.log('baseData', baseData)
  console.log('sortOutData', sortOutData)
  loadingInstance.close()
}

// 置空数据
const clearExcelData = (resData) => {
  exlcelData.header = {}
  exlcelData.results = {}
  sortOutData.baseArr = []
  sortOutData.idArr = []
  sortOutData.skuArr = []
  sortOutData.skuIdArr = []
  sortOutData.orderArr = []
  sortOutData.orderTypeArr = []
  sortOutData.takeOverDetailArr = []
  sortOutData.takeOverTimeArr = []
  baseData.ordersFilterPsCount = 0
  resData && Object.assign(exlcelData, resData)
}

// 订单选择性过滤
const ordersFilterPsHandle = (resData) => {
  if (baseData.ordersFilterPs.length <= 0) {
    ElMessage({
      message: '暂未使用订单备注过滤，本次订单报表将不会进行备注过滤',
      type: 'error',
      showClose: true,
      duration: 6000
    })
  } else {
    const unPsOrderList = []
    for (const i in resData.results) {
      let limitSwitch = false
      for (const psIndex in baseData.ordersFilterPs) {
        const psItem = baseData.ordersFilterPs[psIndex]
        limitSwitch = false
        if (resData.results[i]['商家备注'].indexOf(psItem) !== -1) {
          limitSwitch = true
          baseData.ordersFilterPsCount += 1
          break
        }
      }
      // 非过滤订单
      if (!limitSwitch) {
        unPsOrderList.push(resData.results[i])
      }
    }
    // 最终把过滤后的订单重新赋值给返回值
    resData.results = unPsOrderList
    ElMessage({
      message: `已过滤备注为${[...baseData.ordersFilterPs]}的订单，共${baseData.ordersFilterPsCount}单`,
      type: 'success',
      showClose: true,
      duration: 10000
    })
  }
  // 数据重置并填充
  clearExcelData(resData)
}

// excel组件回调
const excelCall = (resData, loadingInstance) => {
  // scene = '' 默认为基础表格
  loadingInstance.close()
  if (!resData?.scene) {
    let baseSceneStatus = false
    const baseSceneArr = ['商品id', '样式ID', '订单号', '商品', '商品规格', '订单状态', '商家实收金额(元)', '订单号']
    // 如果是默认数据表上传，则遵循默认表格规范
    for (const i in baseSceneArr) {
      if (resData.header.indexOf(baseSceneArr[i]) !== -1) {
        baseSceneStatus = true
      }
    }
    if (!baseSceneStatus) {
      ElMessage({
        message: `外部报表包含必须：${baseSceneArr}`,
        type: 'error',
        showClose: true,
        duration: 6000
      })
      return
    }
    ElMessage.success('外部报表上传成功')
    sortOutData.fileName = resData.rawFile.name
    // 订单选择性过滤
    ordersFilterPsHandle(resData)
    // 顶层时间
    sortOutHandle(loadingInstance)
  }
}

// 点击调用子组件的上传事件
const comHandleHandleUpload = (scene) => {
  UploadExcelCom.value && UploadExcelCom.value.handleUpload('', scene)
}

// 获取后勤模板列表
const getLogisticsListHandle = async () => {
  baseData.selectData = [
    {
      value: '',
      label: '不使用后勤模板'
    }
  ]
  const resData = await logisticsTemplateList({
    template_name: apiData.templateName
  })
  if (resData?.length > 0) {
    const resList = resData.reverse()
    for (const i in resList) {
      baseData.selectData.push({
        value: resList[i].id,
        label: resList[i].name
      })
    }
  }
  baseData.selectVal = ''
}

// 获取sku成本表
const getSkucostListHandle = async () => {
  baseData.selectDataSkuCost = [
    {
      value: '',
      label: '不使用sku成本表'
    }
  ]
  const resData = await skuCostList({
    template_name: apiData.skuCostTemplateName
  })
  if (resData.length > 0) {
    const resList = resData.reverse()
    for (const i in resList) {
      baseData.selectDataSkuCost.push({
        value: resList[i].id,
        label: resList[i].name
      })
    }
  }
  baseData.selectValSkuCost = ''
}

// 获取运营费用列表
const getOperateFeeListHandle = async () => {
  baseData.selectDataOperateFee = [
    {
      value: '',
      label: '不使用运营费用表'
    }
  ]
  const resData = await operateFeeList({
    template_name: apiData.operateFeeTemplateName
  })
  if (resData.length > 0) {
    const resList = resData.reverse()
    for (const i in resList) {
      baseData.selectDataOperateFee.push({
        value: resList[i].id,
        label: resList[i].name
      })
    }
  }
  baseData.selectValOperateFee = ''
}
// 获取运营费用详情 下拉选择相关
const selectOperateFeeHandle = async (val) => {
  console.error('下拉选择相关', sortOutData, val)
  baseData.selectValOperateFee = val
  const resData = await operateFeeDetail({
    template_id: baseData.selectValOperateFee
  })
  sortOutData.templateDefindOperateFee = resData
}
// api交互相关
// api交互相关
// api交互相关
const apiData = reactive({
  templateName: '',
  skuCostTemplateName: '',
  operateFeeTemplateName: ''
})

// 保存报表分析相关 start
// 展示保存弹窗
const saveReportShowHandle = () => {
  baseData.SimpleDialogShow = true
}
// 保存报表数据
const submitReportHandle = async () => {
  if (Object.keys(sortOutData.takeOverDetailArr).length === 0) {
    ElMessage.error('请先上传报表数据')
    return
  }
  const { selectVal, selectValSkuCost, selectValOperateFee } = baseData
  const arrJsonToStr = []
  // 2024年12月29日02:09:53 注释掉未平铺的数据takeOverDetailArr，改成平铺后的deconstructionDataList
  // 2024年12月29日02:10:28 如果后续要上传订单详情，那么takeOverDetailArr依旧要使用到
  // const arrJsonToPureStr = []
  // for (const i in sortOutData.takeOverDetailArr) {
  //   arrJsonToStr.push(JSON.stringify(sortOutData.takeOverDetailArr[i]))
  //   // 清除开始：由于提交到后端的时候大小超出限制导致Add失败，决定清除对应的list后再post
  //   // 清除每日订单详情list
  //   delete sortOutData.takeOverDetailArr[i].list
  //   // 清除每日订单状态详情list
  //   sortOutData.takeOverDetailArr[i].status.map(item => {
  //     delete item.list
  //   })
  //   // 清除结束
  //   arrJsonToPureStr.push(JSON.stringify(sortOutData.takeOverDetailArr[i]))
  // }
  for (const i in sortOutData.deconstructionDataList) {
    if (sortOutData.deconstructionDataList[i].date === '合计') {
      sortOutData.deconstructionDataList[i].date = '1970-01-01'
    }
    arrJsonToStr.push(JSON.stringify(sortOutData.deconstructionDataList[i]))
  }
  const readyPost = {
    table_id: md5(JSON.stringify(arrJsonToStr)), // 此处为数据md5之后的值
    table_name: ruleForm.templateName,
    logistics_template_id: selectVal || '',
    sku_cost_template_id: selectValSkuCost || '',
    operate_template_id: selectValOperateFee || '',
    analysis_result: arrJsonToStr
  }
  console.error('readyPostreadyPost', readyPost, Object.keys(sortOutData.takeOverDetailArr).length)
  await reportAnalysisAdd(readyPost)
  baseData.SimpleDialogShow = false
  ruleForm.templateName = ''
  ElMessage.success('报表分析保存成功')
}
const refreshHandle = (type) => {
  switch (type) {
    case 'template':
      getLogisticsListHandle()
      break
    case 'skuCost':
      getSkucostListHandle()
      break
    case 'operateFee':
      getOperateFeeListHandle()
      break
  }
}

onMounted(() => {
  getLogisticsListHandle()
  getSkucostListHandle()
  getOperateFeeListHandle()
})

// 进行最后的数据解构，用于表格展示
const deconstructionDataShow = (allData) => {
  const dataShowList = []
  let dataShowObj = {} // 订单基础

  const isHouQin = (Object.keys(allData.templateDefind).length !== 0) // 是否使用了后勤模板
  const isSkuCost = (Object.keys(sortOutData.templateDefindSkuCost).length !== 0) // 是否使用了SKU成本表
  let isYunYing = false // 是否使用了运营费用表

  // 合计
  // 初始化合计Item
  sortOutData.deconstructionSummary = {}

  allData.takeOverDetailArr.map((item, index) => {
    dataShowObj = {}
    // dataStatusList = []
    const { date, orderNum, moneySum, status, trueOrders, feeLabor, feeExpressdelivery, expend } = item
    // 订单状态：订单类型，订单类型总金额， 订单类型订单数
    status.map(statusItems => {
      const { type: statusType, orderNum: statusOrderNum, moneySum: statusMoneySum } = statusItems
      dataShowObj[statusType] = {
        orderNum: statusOrderNum,
        moneySum: statusMoneySum.toFixed(2)
      }
    })
    // 订单基础：日期，订单数，销售额，客单价
    Object.assign(dataShowObj, {
      date: date,
      orderNum: orderNum,
      moneySum: moneySum.toFixed(2),
      unitPrice: (moneySum.toFixed(2) / orderNum).toFixed(2)
    })
    if (isHouQin) { // 使用了后勤模板
      Object.assign(dataShowObj, {
        todOrderNumber: trueOrders.orderNum,
        todMoneySum: (trueOrders.moneySum).toFixed(2),
        laborCost: feeLabor.toFixed(2),
        logisticsCost: feeExpressdelivery.toFixed(2)
      })
      if (isSkuCost) { // 使用了sku成本表
        Object.assign(dataShowObj, {
          todSkuCostSum: (trueOrders.skuCostSum).toFixed(2), // 商品成本
          todGrossMargin: (trueOrders.moneySum - trueOrders.skuCostSum).toFixed(2), // 销售毛利
          todGrossMarginDot: (((trueOrders.moneySum - trueOrders.skuCostSum) / trueOrders.moneySum) * 100).toFixed(2) + '%' // 毛利率
        })
      }
      if ((Object.keys(expend?.duizhang).length !== 0) || (Object.keys(expend?.tuiguang).length !== 0)) {
        isYunYing = true
        Object.assign(dataShowObj, {
          operPayment: (expend.duizhangSum + expend.tuiguangSum).toFixed(2), // 广告/扣款/打款等支出
          operProfit: ((trueOrders.moneySum - trueOrders.skuCostSum).toFixed(2) - feeLabor.toFixed(2) - feeExpressdelivery.toFixed(2) - (expend.duizhangSum + expend.tuiguangSum).toFixed(2)).toFixed(2), // 利润
          operProfitDot: (((trueOrders.moneySum - trueOrders.skuCostSum).toFixed(2) - feeLabor.toFixed(2) - feeExpressdelivery.toFixed(2) - (expend.duizhangSum + expend.tuiguangSum).toFixed(2)).toFixed(2) / (trueOrders.moneySum) * 100).toFixed(2) + '%', // 利润率
          operJinbao: expend.duizhang.jinbao_yongjin || 0.00,
          operOhterFuwu: expend.duizhang.other_fuwu || 0.00,
          operJishuFuwu: expend.duizhang.jishufuwu_fee || 0.00,
          operKoukuan: expend.duizhang.koukuan || 0.00,
          operYouhuiquan: expend.duizhang.youhuiquan || 0.00,
          operHaoping: expend.duizhang.haoping_lijin || 0.00,
          operOtherPayment: expend.duizhang.other_zhichu || 0.00,
          operTuiGuang: expend.tuiguang.tuiguang_fee || 0.00
        })
      }
    }
    dataShowList.push(dataShowObj)
    sortOutData.deconstructionDataList = dataShowList
    // 初始化合计Item
    index === 0 && initSummaryItem(dataShowObj, sortOutData.deconstructionSummary)
    // 执行合计Item
    initSummaryItem(dataShowObj, sortOutData.deconstructionSummary)
  })
  // 做合计最后的数据调整，去除精度损失，需平均键名均分，加单位
  summarySortOut(sortOutData.deconstructionSummary, allData.takeOverDetailArr.length)
  console.log(sortOutData.deconstructionSummary)
  // 追加到汇总的第一位
  sortOutData.deconstructionDataList.unshift(sortOutData.deconstructionSummary)

  // 根据 解构数据列表 设置表格头部
  sortOutData.deconstructionHead = [
    { label: '时间', prop: 'date', width: '100', fixed: true },
    { label: '销售额', prop: 'moneySum', unit: '元', width: '120', fixed: true },
    { label: '订单数', prop: 'orderNum', unit: '单', width: '100', fixed: true },
    { label: '客单价', prop: 'unitPrice', unit: '元', width: '100', fixed: true },
    { label: '订单详情', prop: 'orderNumDetail', width: '140' }
  ]
  const headHouQin = [
    { label: '人工费用', prop: 'laborCost', unit: '元', width: '120' },
    { label: '物流费用', prop: 'logisticsCost', unit: '元', width: '120' },
    { label: '真实销售额', prop: 'todMoneySum', unit: '元', width: '120' },
    { label: '真实订单数', prop: 'todOrderNumber', unit: '单', width: '120' }
  ]
  const headSkuCost = [
    { label: '商品成本', prop: 'todSkuCostSum', unit: '元', width: '120' },
    { label: '销售毛利', prop: 'todGrossMargin', unit: '元', width: '120' },
    { label: '毛利率', prop: 'todGrossMarginDot', width: '100' }
  ]
  const headYunYing = [
    { label: '广告/扣款/打款等支出', prop: 'operPayment', unit: '元', width: '120' },
    { label: '利润', prop: 'operProfit', unit: '元', width: '120' },
    { label: '利润率', prop: 'operProfitDot', width: '120' },
    { label: '进宝佣金', prop: 'operJinbao', unit: '元', width: '120' },
    { label: '其他服务', prop: 'operOhterFuwu', unit: '元', width: '120' },
    { label: '技术服务', prop: 'operJishuFuwu', unit: '元', width: '120' },
    { label: '扣款', prop: 'operKoukuan', unit: '元', width: '120' },
    { label: '优惠券', prop: 'operYouhuiquan', unit: '元', width: '120' },
    { label: '好评支出', prop: 'operHaoping', unit: '元', width: '120' },
    { label: '其他支出', prop: 'operOtherPayment', unit: '元', width: '120' },
    { label: '推广费', prop: 'operTuiGuang', unit: '元', width: '120' }
  ]
  isHouQin && sortOutData.deconstructionHead.push(...headHouQin)
  isSkuCost && sortOutData.deconstructionHead.push(...headSkuCost)
  isYunYing && sortOutData.deconstructionHead.push(...headYunYing)
  console.error('Comedy', sortOutData.deconstructionDataList)
}

</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
.excelBox {
  .detailFormDialogBox {
    @include dialogBoxBase;
    .el-dialog {
      height: calc( 100% - 80px);
      width: 100vw;
    }
    .card-box {
      .card-bd {
        padding-top: 24px;
      }
    }
  }
  .goodsForm {
    @include formBoxBase;
    height: calc( 100% - 100px);
    &Box {
      width: calc(100% - 148px);
    }
  }
  .echart-demo {
    display: flex;
    flex-wrap: wrap;
    .echart-box {
      width: 100%;
      height: calc(100vh - 257px);
    }
  }
  .selectDownBox {
    transition: none;
    + .el-button {
      margin-left: 12px;
    }
  }
  .height-auto-table {
    margin-top: 12px;
    .el-scrollbar {
      height: calc(100vh - 530px);
      width: 100%;
      padding: 0;
    }
  }
  .height-line-table {
    margin-top: 12px;
    .el-scrollbar {
      height: auto !important;
      width: 100%;
      padding: 0;
    }
  }
  .file-save-entry {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
    p {
      color: #606266;
    }
  }
  .status-table-clumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ordersFilterSelect {
    min-height: 40px;
    height: auto;
    .selectLabel {
      color: #606266;
      margin: 0;
      padding-right: 12px;
      font-size: 14px;
      min-width: 80px;
      text-align: right;
    }
    .orders-filter-select {
      width: calc(50% - 80px);
    }
    .upload-btn {
      margin-left: 12px;
    }
  }
}
</style>
